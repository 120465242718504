<template>
  <div :class="['seal-manager',clientWidth<=mobileWidth?'mobile-bg':'']">
    <div class="seal-manager-content common-mobile-content">
      <div class="manager-info">
        <div class="common-info-content">
          <img src="../../../assets/imgs/icon_guanli@2x.png">
          <div class="apply-title">电子印章管理</div>
        </div>
        <div class="apply-js common-apply-js">
          涵盖用户管理、使用授权、应用管理等功能，实现用章溯源，多维度确保用章安全。
        </div>
        <div class="experience-btn" @click="toView" v-if="clientWidth>mobileWidth">马上体验</div>
      </div>
      <img src="../../../assets/imgs/pic_guanli@2x.png"
        class="pic-manager common-pic-icon">
      <div class="experience-btn" @click="toView" v-if="clientWidth<=mobileWidth">马上体验</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SealManager',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toView() {
      window.open('http://app.lngsyz.com', '_black');
    },
  },
};
</script>

<style lang="less" scoped>
  .seal-manager {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    // background-color: rgba(221, 221, 221, 0.4);
    .seal-manager-content{
      display: flex;
      height: 488px;
      justify-content: center;
      align-self: center;
    }
    .pic-manager {
      width: 554px;
      height: 488px;
      margin-left: 75px;
    }
    .manager-info {
      width: 360px;
      font-family: PingFangSC-Regular, PingFang SC;
      img {
        width: 72px;
        height: 72px;
        margin-top: 67px;
      }
      .apply-title {
        padding-left: 8px;
        margin-top: 24px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .apply-js {
        padding-left: 8px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 30px;
      }
    }
    .experience-btn {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #597EF7;
      border-radius: 30px;
      font-size: 21px;
      font-weight: 400;
      color: #FFFFFF;
      letter-spacing: 1px;
      margin-top: 24px;
      cursor: pointer;
      margin-top: 120px;
      opacity: 0;
      transition: opacity 1s ease-out 1.2s,
      margin-top 1s ease-out 1.2s, color 0.3s, background-color 0.3s;
    }
  }
</style>
