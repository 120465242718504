<template>
  <div class="home-page">
    <common-header
      :client-width="clientWidth"
      current-menu="no"
      :is-show-menu="true">
    </common-header>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section section1">
        <div class="bg-info">
          <div class="first-content">
            <template v-if="clientWidth>760">
              <img src="../../assets/imgs/title@2x.png" class="sys-title">
              <div class="company-introduce">
                <div>
                  致力于东方印章文化的传承与创新，提供全方位、多维度的企业电子印章申请、应用、管理等服务。                </div>
              </div>
            </template>
            <template v-else>
              <div>
                <img src="../../assets/imgs/mobile/title@2x.png" class="sys-mobile-title">
                <div class="mobile-company-introduce">
                  致力于东方印章文化的传承与创新，提供全方位、多维度的企业电子印章申请、应用、管理等服务。
                </div>
              </div>
            </template>
            <div class="learn-more" @click="$router.go(-1)">了解更多</div>
          </div>
        </div>
      </div>
<!--      <div class="section section2">-->
<!--        <seal-apply ref="applyInfo" :clientWidth="clientWidth"></seal-apply>-->
<!--      </div>-->
      <div class="section section3">
        <seal-sign ref="sealSign" :clientWidth="clientWidth"></seal-sign>
      </div>
      <div class="section section5">
        <seal-check ref="sealCheck" :clientWidth="clientWidth"></seal-check>
      </div>
      <div class="section section6">
        <seal-system ref="sealSystem" :clientWidth="clientWidth"></seal-system>
      </div>
      <div class="section section4">
        <seal-manager ref="sealManager" :clientWidth="clientWidth"></seal-manager>
      </div>
      <div class="section section7">
        <person-sign :clientWidth="clientWidth"></person-sign>
        <mobile-footer v-show="clientWidth<=mobileWidth"/>
      </div>
      <div class="section fp-auto-height">
        <common-footer v-show="clientWidth>mobileWidth"></common-footer>
      </div>
    </full-page>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
// import SealApply from './components/SealApply.vue';
import SealManager from './components/SealManager.vue';
import SealSign from './components/SealSign.vue';
import SealCheck from './components/SealCheck.vue';
import SealSystem from './components/SealSystem.vue';
import PersonSign from './components/PersonSign.vue';

export default {
  name: 'Index',
  components: {
    CommonHeader,
    CommonFooter,
    MobileFooter,
    // SealApply,
    SealManager,
    SealSign,
    SealCheck,
    SealSystem,
    PersonSign,
  },
  data() {
    return {
      clientWidth: 1920,
      options: {
        // licenseKey: 'YOUR_KEY_HEERE',
        paddingTop: '76px',
        slidesNavigation: true,
      },
    };
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  created() {
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
      this.options.paddingTop = '50px';
      console.log(this.clientWidth);
    },
  },
};
</script>

<style lang="less" scoped>
  .home-page {
    .bg-info {
      height: 100%;
      width: 100%;
      background-image: url("../../assets/imgs/bg_head0@2x.png");
      background-size: 100% 100%;
      display: flex;
      z-index: 99;
      .sys-title {
        width: 768px;
        height: 56px;
        transition: all 1s;
        transform: scale(0.5, 0.5);
        opacity: 0;
      }
      .company-introduce {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
        line-height: 25px;
        width: 1080px;
        text-align: center;
        margin-top: 42px;
        transition: all 1s;
        transform: scale(0.5, 0.5);
        opacity: 0;
        .com-detail {
          margin-top: 20px;
        }
      }
      .learn-more {
        width: 200px;
        height: 60px;
        background: #FFFFFF;
        border-radius: 30px;
        line-height: 60px;
        text-align: center;
        font-size: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #597EF7;
        letter-spacing: 1px;
        cursor: pointer;
        margin: 40px auto 0;
      }
      .first-content{
        align-self: center;
        text-align: center;
        margin: auto;
      }
    }
    .my-swiper{
      margin-top: 67px;
    }
  }
  .section2.active{
    /deep/ .apply-title{
      opacity: 1;
      transform: scale(1,1);
      transition-delay: .5s;
    }
  }
  .section2.active,.section3.active,.section4.active,.section5.active,.section6.active{
    /deep/.apply-js{
      opacity: 1;
      transform: scale(1,1);
    }
    /deep/ .experience-btn{
      opacity: 1;
      margin-top: 24px;
    }
  }

  .section1.active{
    .bg-info{
      .sys-title,.company-introduce{
        transition-delay: 0.7s;
        transform: scale(1, 1);
        opacity: 1;
      }
    }
  }
  .section2.active{
    /deep/.seal-apply{
      .sq-bg,.pc-sq-bg{
        -webkit-animation:fadeinR 1s 1;
        -moz-animation:fadeinR 1s 1;
        -ms-animation:fadeinR 1s 1;
        animation:fadeinR 1s 1;
      }
    }
  }
  .section3.active{
    /deep/.seal-sign{
      .pic-sign{
        -webkit-animation:fadeinL 1s 1;
        -moz-animation:fadeinL 1s 1;
        -ms-animation:fadeinL 1s 1;
        animation:fadeinL 1s 1;
      }
      .apply-title{
        animation: lightSpeedInRight 2s 1;
      }
      .apply-js{
        animation: fadeInUp 2s 1;
      }
    }
  }
  .section4.active{
    /deep/ .seal-manager{
      .pic-manager{
        animation: bounceinB 1s 1;
      }
      .apply-title{
        animation: lightSpeedInLeft 2s 1;
      }
    }
  }
  .section5.active{
    /deep/ .seal-check{
      .pic-check{
        animation: fadein 3s 1;
      }
      .apply-title{
        animation: zoomIn 2s 1;
      }
      .apply-js{
        animation: flipInX 2s 1;
      }
    }
  }
  .section6.active{
    /deep/ .seal-system{
      // .sys-pic-content{
      //   animation: fadeInTopLeft 1s 1;
      // }
     .apply-title{
        animation: slideInDown 2s 1;
      }
    }
  }
  .section7.active{
    /deep/.person-sign-content{
      animation: bounce 3s 1;
    }
  }
  .fp-auto-height{
    padding: 0!important;
  }
  /* 淡入 */
  @-webkit-keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  @-moz-keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  @-ms-keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  @keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  /* 淡入-从左 */
  @-webkit-keyframes fadeinL{
    0%{opacity:0;-webkit-transform:translateX(-200px);}
    100%{opacity:1;-webkit-transform:translateX(0);}
  }
  @-moz-keyframes fadeinL{
    0%{opacity:0;-moz-transform:translateX(-200px);}
    100%{opacity:1;-moz-transform:translateX(0);}
  }
  @-ms-keyframes fadeinL{
    0%{opacity:0;-ms-transform:translateX(-200px);}
    100%{opacity:1;-ms-transform:translateX(0);}
  }
  @keyframes fadeinL{
    0%{opacity:0;transform:translateX(-200px);}
    100%{opacity:1;transform:translateX(0);}
  }

  /* 淡入-从右 */
  @-webkit-keyframes fadeinR{
    0%{opacity:0;-webkit-transform:translateX(200px);}
    100%{opacity:1;-webkit-transform:translateX(0);}
  }
  @-moz-keyframes fadeinR{
    0%{opacity:0;-moz-transform:translateX(200px);}
    100%{opacity:1;-moz-transform:translateX(0);}
  }
  @-ms-keyframes fadeinR{
    0%{opacity:0;-ms-transform:translateX(200px);}
    100%{opacity:1;-ms-transform:translateX(0);}
  }
  @keyframes fadeinR{
    0%{opacity:0;transform:translateX(200px);}
    100%{opacity:1;transform:translateX(0);}
  }

  /* 弹入-从下 */
  @-webkit-keyframes bounceinB{
    0%{opacity:0;-webkit-transform:translateY(200px);}
    60%{opacity:1;-webkit-transform:translateY(-60px);}
    80%{-webkit-transform:translateY(20px);}
    100%{-webkit-transform:translateY(0);}
  }
  @-moz-keyframes bounceinB{
    0%{opacity:0;-moz-transform:translateY(200px);}
    60%{opacity:1;-moz-transform:translateY(-60px);}
    80%{-moz-transform:translateY(20px);}
    100%{-moz-transform:translateY(0);}
  }
  @-ms-keyframes bounceinB{
    0%{opacity:0;-ms-transform:translateY(200px);}
    60%{opacity:1;-ms-transform:translateY(-60px);}
    80%{-ms-transform:translateY(20px);}
    100%{-ms-transform:translateY(0);}
  }
  @keyframes bounceinB{
    0%{opacity:1;transform:translateY(200px);}
    60%{opacity:1;transform:translateY(-60px);}
    80%{transform:translateY(20px);}
    100%{transform:translateY(0);}
  }
  /* 转入-从左下 */
  @-webkit-keyframes rotateinefrB{
    0%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(90deg);opacity:0;}
    100%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(0);opacity:1;}
  }
  @-moz-keyframes rotateinefrB{
    0%{-moz-transform-origin:right bottom;-moz-transform:rotate(90deg);opacity:0;}
    100%{-moz-transform-origin:right bottom;-moz-transform:rotate(0);opacity:1;}
  }
  @-ms-keyframes rotateinefrB{
    0%{-ms-transform-origin:right bottom;-ms-transform:rotate(90deg);opacity:0;}
    100%{-ms-transform-origin:right bottom;-ms-transform:rotate(0);opacity:1;}
  }
  @keyframes rotateinefrB{
    0%{transform-origin:right bottom;transform:rotate(90deg);opacity:0;}
    100%{transform-origin:right bottom;transform:rotate(0);opacity:1;}
  }

  /* 弹跳 */
  @-webkit-keyframes bounce{
    0%,20%,50%,80%,100%{-webkit-transform:translateY(0);}
    40%{-webkit-transform:translateY(-30px);}
    60%{-webkit-transform:translateY(-15px);}
  }
  @-moz-keyframes bounce{
    0%,20%,50%,80%,100%{-moz-transform:translateY(0);}
    40%{-moz-transform:translateY(-30px);}
    60%{-moz-transform:translateY(-15px);}
  }
  @-ms-keyframes bounce{
    0%,20%,50%,80%,100%{-ms-transform:translateY(0);}
    40%{-ms-transform:translateY(-30px);}
    60%{-ms-transform:translateY(-15px);}
  }
  @keyframes bounce{
    0%,20%,50%,80%,100%{transform:translateY(0);}
    40%{transform:translateY(-30px);}
    60%{transform:translateY(-15px);}
  }
@import '../../less/mobile.less';
@import "../../static/fullpage.min.css";
</style>
