<template>
  <div class="seal-check">
    <div class="seal-check-content common-mobile-content">
      <div class="check-info">
        <div class="common-info-content">
          <img src="../../../assets/imgs/icon_heyan@2x.png">
          <div class="apply-title">电子印章和电子文档核验</div>
        </div>
        <div class="apply-js common-apply-js">
          支持电子印章及文档核验；<br/>
          支持多场景、不同平台以及政务、商务签章文档互验互通。
        </div>
        <img src="../../../assets/imgs/pic_heyan@2x.png"
          class="pic-check common-pic-icon common-pic-auto"
          v-if="clientWidth<=mobileWidth">
        <div class="experience-btn" @click="toCheck">马上体验</div>
      </div>
      <img src="../../../assets/imgs/pic_heyan@2x.png"
       class="pic-check" v-if="clientWidth>mobileWidth">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SealCheck',
  data() {
    return {
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toCheck() {
      window.open('https://kq.lngsyz.com/api/application/tofileVerify', '_black');
    },
  },
};
</script>

<style lang="less" scoped>
  .seal-check {
    height: 100%;
    display: flex;
    justify-content: center;
    .seal-check-content{
      display: flex;
      height: 488px;
      justify-content: center;
      align-self: center;
    }
    .pic-check {
      width: 672px;
      height: 427px;
    }
    .check-info {
      width: 360px;
      margin: 26px 70px 0 0;
      font-family: PingFangSC-Regular, PingFang SC;
      img {
        width: 56px;
        height: 56px;
      }
      .apply-title {
        padding-left: 6px;
        margin-top: 32px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .apply-js {
        padding-left: 6px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 30px;
      }
      .experience-btn {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #597EF7;
        border-radius: 30px;
        font-size: 21px;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-top: 24px;
        cursor: pointer;
        margin-top: 120px;
        opacity: 0;
        transition: opacity 1s ease-out 1.2s,
        margin-top 1s ease-out 1.2s, color 0.3s, background-color 0.3s;
      }
    }
    .common-pic-auto{
      height: auto !important;
    }
  }
</style>
